.date-selector {
  width: 277px;
  height: 300px;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(5, 49, 73, 0.08);
  border-radius: 16px;
  user-select: none;
  z-index: 3;
  top: 50px;
  right: 0px;
}

.send-calendar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  user-select: none;

  .week-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    user-select: none;

    .week-day {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
    }
  }

  .week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    .day {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 32px;
      position: relative;
      outline: 0;
    }

    .day:hover {
      color: #8927ef;
      background: #f5f9fc;
      border-radius: 20px;
    }

    .weekend {
      color: #053149;
      opacity: 0.5;
    }

    .fade {
      color: #a0a0a0;
      opacity: 0.3;
    }

    .selected-day {
      color: white;
      background: #8927ef;
      border-radius: 20px;
    }

    .range-day {
      color: #8927ef;
    }

    .campaign-day {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid #ea5454;
      cursor: pointer;
    }
  }

  .month-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    user-select: none;

    img {
      cursor: pointer;
      min-width: 10px;
      min-height: 10px;
    }
  }
}
